<template>

  <div
    class="p-1 modalScroll"
    style="width: auto; height: auto;"
  >
    <div
      class="saj-title d-flex justify-content-start mb-1"
      style="font-weight: 500"
    >
      {{ $t ("Work Location Info") }}
    </div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-form-group
          class="saj-text"
          :label="$t('Work Location Name')"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Work Location Name')"
            rules="required"
          >
            <b-form-input
              v-model="name"
              class="saj-form-text"
              :readonly="roles.isHR"
              :placeholder="$t(`Enter Work Location's Name`)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>

      <b-form class="mt-2">
        <b-form-group
          class="saj-text"
          :label="$t('Description')"
        >
          <!-- <validation-provider
            #default="{ errors }"
            :name="$t('Work Location Name')"
            rules="required"
          > -->
          <b-form-textarea
            v-model="details"
            class="saj-form-text"
            :placeholder="$t('Description')"
            :readonly="roles.isHR"
            rows="6"
          />
        <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group>
      </b-form>
    </validation-observer>
    <div
      class="d-flex justify-content-end"
      style=""
    >
      <button
        class="btn btn-close mt-1 ml-1 mb-1 saj-button"
        aria-label="Close modal"
        style="
                  color: white;
                  background: #ff0000;
                "
        @click="close()"
      >
        {{ $t('Back') }}
      </button>
      <button
        class="btn mt-1 ml-1 mb-1 saj-button"
        aria-label="submit modal"
        style="
                  color: white;
                  background: #0b103c !important;
                  border-color: #0b103c !important;
                "
        :style="roles.isHR ? `display: none;` : ``"
        @click="isUpdate ? updateBranch() : createBranch()"
      >
        {{ $t('Save') }}
      </button>
    </div>
  </div>

</template>
<script>
import {
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
} from "bootstrap-vue"
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import SAJToast from '@/component/saj-toastification.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BButton,
    // BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    // BInputGroup,
    // BFormGroupAppend,
    // BCard,
    // BFormTimepicker,
    // BCardCode,
    // BRow,
    // BCol,
    // BCalendar,
    // BCardText,
    ValidationProvider,
    ValidationObserver,
  },

  props: {
    branchData: {
      type: Object,
      default: () => {},
    },

    isUpdate: {
      type: Boolean,
      default: false,
    },

    branchId: {
      type: Number,
      default: null,
    },

  },

  data() {
    return {
      name: this.branchData !== undefined ? this.branchData.name : null,
      // eslint-disable-next-line no-nested-ternary
      details: this.branchData !== undefined ? this.branchData.details !== 'null' ? this.branchData.details : '-' : null,
      required,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },

  beforeMount() {
    this.id = this.$route.params.id
  },
  mounted() {

  },
  methods: {
    close() {
      this.$emit('close')
    },
    createBranch() {
      this.$refs.simpleRules.validate().then(success => {
        if (success){
          const data = new FormData()
          data.append('company_id', this.id)
          data.append('name', this.name)
          data.append('details', this.details)

          this.$axios.post(`${this.$baseUrl}/branch/store_branch`, data).then(() => {
            this.$emit('close')
            this.$emit('add-worklocation')
            setTimeout(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$i18n.t(`Work Location successfully added`)}.`,
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    titleColor: '#000',
                  },
                },
                {
                  position: "top-right",
                  type: 'info',
                },
              )
            }, 1000)
          })
        }
      })
    },
    updateBranch() {
      const data = new FormData()
      data.append('company_id', this.id)
      data.append('branch_id', this.branchData.id)
      data.append('name', this.name)
      data.append('details', this.details === "" ? '-' : this.details)

      this.$axios.post(`${this.$baseUrl}/branch/update_branch`, data).then(() => {
        this.$emit('close')
        this.$emit('update-worklocation')
        setTimeout(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Work location info successfully updated')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        }, 1000)
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// [dir] .b-form-btn-label-control.form-control > .dropdown-menu {
//   padding-bottom: 3rem;
// }
// [dir=ltr] .mr-auto, [dir=ltr] .mx-auto {
//     margin-right: auto !important;
//     padding-bottom: 2rem;
// }

</style>
